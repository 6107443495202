export const getFirstImage = string => {
  const getImages = str => {
    const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
    const images = [];
    let img;
    while ((img = imgRex.exec(str))) {
      images.push(img);
    }
    return images;
  };
  let imgs = getImages(string);

  if (imgs && imgs.length > 0) {
    return imgs[0][1];
  } else return string;
};
